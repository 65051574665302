import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import mapBoy from '../../assets/images/arena/map-boy.svg';
import '../../assets/scss/custom/pages/_arena_auth.scss';
import coinImg from '../../assets/images/arena/coin-shadow.svg';
import { useNavigate } from 'react-router-dom';

// import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';

function MapLanding(){
    document.body.style = 'background: #DCE7ED;';
    const navigate = useNavigate();
    return(
        <div className="map-container vh-100  d-flex align-items-center justify-content-center">
            <Container fluid>
            <Row>
                <Col xs={12} sm={5} className="d-flex align-items-center justify-content-center">
                    <div>
                        <img src={mapBoy} alt='' className='landing1-img mg-left-015'/>
                    </div>
                </Col>
                <Col xs={12} sm={7}>
                    <div className='map-landing-main'>
                        <h2>Wow you are done...</h2>
                        <div className='map-landing-subtitle align-items-center justify-content-center p-2 mt-4'>
                            <div className='pt-1'>
                                <img src={coinImg} alt='' className="px-1 pt-1" ></img> <span className='black-bold-tx pt-5'> Gold coin you earned <span className='won-coin'> {Math.floor(Math.random() * 50) + 50} </span> </span>
                            </div>   
                        </div>
                        <p className='orange-bold-tx mt-3'> Skeptical scout: </p>
                        <p className='map-content'>
                            Is this journey for me? Is it worth it? These are the thoughts that 
                            plague you as you begin your adventure. Worry not, you're in the company 
                            of other legendary skeptics who were unsure of whether they even wanted to 
                            begin a great journey, like Peter Parker (AKA, Spider-Man), Simba from 
                            “The Lion King”, and even Bilbo Baggins himself! What finally pushed all these
                            heroes into action? It was finding their own purpose for taking on a big adventure. 
                            And when you're done visit Lenses Only! 
                        </p>
                        <div className='d-flex justify-content-center justify-content-sm-start'>
                        <button className='orange-btn mt-3' onClick={() => navigate("/arena-landing")}>Got It! </button>
                        </div>
                    </div>
                </Col>
            </Row>
            </Container>
        </div>
    )
}

export default MapLanding;