/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      description
      S3IngestFileURL
      streetAddress
      city
      state
      zipcode
      clientLogoURL
      status
      campaigns {
        nextToken
        startedAt
        __typename
      }
      contactName
      contactPhone
      contactEmail
      imgBanner
      slogan
      note
      orgState
      services {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        S3IngestFileURL
        streetAddress
        city
        state
        zipcode
        clientLogoURL
        status
        contactName
        contactPhone
        contactEmail
        imgBanner
        slogan
        note
        orgState
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrganizations = /* GraphQL */ `
  query SyncOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrganizations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        S3IngestFileURL
        streetAddress
        city
        state
        zipcode
        clientLogoURL
        status
        contactName
        contactPhone
        contactEmail
        imgBanner
        slogan
        note
        orgState
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      name
      description
      value
      url
      organization
      thumbnail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        value
        url
        organization
        thumbnail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncServices = /* GraphQL */ `
  query SyncServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        value
        url
        organization
        thumbnail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const servicesInOrganization = /* GraphQL */ `
  query ServicesInOrganization(
    $organization: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    servicesInOrganization(
      organization: $organization
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        value
        url
        organization
        thumbnail
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      firstName
      lastName
      mobilePhone
      email
      clientState
      optInState
      preferredContactMode
      sentCount
      campaign
      currentJourneyInstance
      previousJourneyInstances
      currentEpisodeInstance
      currentQuizInstance
      organization
      affiliation
      rewardPoints
      otherPoints
      streetAddress
      streetAddress2
      city
      state
      zipcode
      sentMessageDetails
      eventDetails
      quizAnswers
      quizPrediction
      cognitoId
      otp
      latestActivity
      currentActivity
      thumbnail
      ClinicianID
      clinicianIDs
      Notes {
        nextToken
        startedAt
        __typename
      }
      files
      services
      personalityStatus
      visitedJourneyEpisodes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        mobilePhone
        email
        clientState
        optInState
        preferredContactMode
        sentCount
        campaign
        currentJourneyInstance
        previousJourneyInstances
        currentEpisodeInstance
        currentQuizInstance
        organization
        affiliation
        rewardPoints
        otherPoints
        streetAddress
        streetAddress2
        city
        state
        zipcode
        sentMessageDetails
        eventDetails
        quizAnswers
        quizPrediction
        cognitoId
        otp
        latestActivity
        currentActivity
        thumbnail
        ClinicianID
        clinicianIDs
        files
        services
        personalityStatus
        visitedJourneyEpisodes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncClients = /* GraphQL */ `
  query SyncClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstName
        lastName
        mobilePhone
        email
        clientState
        optInState
        preferredContactMode
        sentCount
        campaign
        currentJourneyInstance
        previousJourneyInstances
        currentEpisodeInstance
        currentQuizInstance
        organization
        affiliation
        rewardPoints
        otherPoints
        streetAddress
        streetAddress2
        city
        state
        zipcode
        sentMessageDetails
        eventDetails
        quizAnswers
        quizPrediction
        cognitoId
        otp
        latestActivity
        currentActivity
        thumbnail
        ClinicianID
        clinicianIDs
        files
        services
        personalityStatus
        visitedJourneyEpisodes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const clientByPhone = /* GraphQL */ `
  query ClientByPhone(
    $mobilePhone: String!
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientByPhone(
      mobilePhone: $mobilePhone
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        mobilePhone
        email
        clientState
        optInState
        preferredContactMode
        sentCount
        campaign
        currentJourneyInstance
        previousJourneyInstances
        currentEpisodeInstance
        currentQuizInstance
        organization
        affiliation
        rewardPoints
        otherPoints
        streetAddress
        streetAddress2
        city
        state
        zipcode
        sentMessageDetails
        eventDetails
        quizAnswers
        quizPrediction
        cognitoId
        otp
        latestActivity
        currentActivity
        thumbnail
        ClinicianID
        clinicianIDs
        files
        services
        personalityStatus
        visitedJourneyEpisodes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const clientByEmail = /* GraphQL */ `
  query ClientByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        mobilePhone
        email
        clientState
        optInState
        preferredContactMode
        sentCount
        campaign
        currentJourneyInstance
        previousJourneyInstances
        currentEpisodeInstance
        currentQuizInstance
        organization
        affiliation
        rewardPoints
        otherPoints
        streetAddress
        streetAddress2
        city
        state
        zipcode
        sentMessageDetails
        eventDetails
        quizAnswers
        quizPrediction
        cognitoId
        otp
        latestActivity
        currentActivity
        thumbnail
        ClinicianID
        clinicianIDs
        files
        services
        personalityStatus
        visitedJourneyEpisodes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const clientsInOrganization = /* GraphQL */ `
  query ClientsInOrganization(
    $organization: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientsInOrganization(
      organization: $organization
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        mobilePhone
        email
        clientState
        optInState
        preferredContactMode
        sentCount
        campaign
        currentJourneyInstance
        previousJourneyInstances
        currentEpisodeInstance
        currentQuizInstance
        organization
        affiliation
        rewardPoints
        otherPoints
        streetAddress
        streetAddress2
        city
        state
        zipcode
        sentMessageDetails
        eventDetails
        quizAnswers
        quizPrediction
        cognitoId
        otp
        latestActivity
        currentActivity
        thumbnail
        ClinicianID
        clinicianIDs
        files
        services
        personalityStatus
        visitedJourneyEpisodes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const clientByClinician = /* GraphQL */ `
  query ClientByClinician(
    $ClinicianID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientByClinician(
      ClinicianID: $ClinicianID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        mobilePhone
        email
        clientState
        optInState
        preferredContactMode
        sentCount
        campaign
        currentJourneyInstance
        previousJourneyInstances
        currentEpisodeInstance
        currentQuizInstance
        organization
        affiliation
        rewardPoints
        otherPoints
        streetAddress
        streetAddress2
        city
        state
        zipcode
        sentMessageDetails
        eventDetails
        quizAnswers
        quizPrediction
        cognitoId
        otp
        latestActivity
        currentActivity
        thumbnail
        ClinicianID
        clinicianIDs
        files
        services
        personalityStatus
        visitedJourneyEpisodes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getClinician = /* GraphQL */ `
  query GetClinician($id: ID!) {
    getClinician(id: $id) {
      id
      firstName
      lastName
      mobilePhone
      email
      streetAddress
      streetAddress2
      city
      state
      zipcode
      thumbnail
      organization
      clients {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listClinicians = /* GraphQL */ `
  query ListClinicians(
    $filter: ModelClinicianFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClinicians(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        mobilePhone
        email
        streetAddress
        streetAddress2
        city
        state
        zipcode
        thumbnail
        organization
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncClinicians = /* GraphQL */ `
  query SyncClinicians(
    $filter: ModelClinicianFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClinicians(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstName
        lastName
        mobilePhone
        email
        streetAddress
        streetAddress2
        city
        state
        zipcode
        thumbnail
        organization
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const clinicianByOrganization = /* GraphQL */ `
  query ClinicianByOrganization(
    $organization: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClinicianFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clinicianByOrganization(
      organization: $organization
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        mobilePhone
        email
        streetAddress
        streetAddress2
        city
        state
        zipcode
        thumbnail
        organization
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getNote = /* GraphQL */ `
  query GetNote($id: ID!) {
    getNote(id: $id) {
      id
      author
      text
      status
      timestamp
      clientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes(
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        text
        status
        timestamp
        clientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncNotes = /* GraphQL */ `
  query SyncNotes(
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        author
        text
        status
        timestamp
        clientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const notesByClient = /* GraphQL */ `
  query NotesByClient(
    $clientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notesByClient(
      clientID: $clientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        author
        text
        status
        timestamp
        clientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      text
      clinicianID
      clientID
      client {
        id
        firstName
        lastName
        mobilePhone
        email
        clientState
        optInState
        preferredContactMode
        sentCount
        campaign
        currentJourneyInstance
        previousJourneyInstances
        currentEpisodeInstance
        currentQuizInstance
        organization
        affiliation
        rewardPoints
        otherPoints
        streetAddress
        streetAddress2
        city
        state
        zipcode
        sentMessageDetails
        eventDetails
        quizAnswers
        quizPrediction
        cognitoId
        otp
        latestActivity
        currentActivity
        thumbnail
        ClinicianID
        clinicianIDs
        files
        services
        personalityStatus
        visitedJourneyEpisodes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      status
      timestamp
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        clinicianID
        clientID
        status
        timestamp
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncNotifications = /* GraphQL */ `
  query SyncNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        text
        clinicianID
        clientID
        status
        timestamp
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getReminder = /* GraphQL */ `
  query GetReminder($id: ID!) {
    getReminder(id: $id) {
      id
      clientId
      scheduleDelivery
      reminderTimestamp
      sentCount
      currentJourneyInstance
      mobilePhone
      reminderType
      organization
      sentLimit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listReminders = /* GraphQL */ `
  query ListReminders(
    $filter: ModelReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReminders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        scheduleDelivery
        reminderTimestamp
        sentCount
        currentJourneyInstance
        mobilePhone
        reminderType
        organization
        sentLimit
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncReminders = /* GraphQL */ `
  query SyncReminders(
    $filter: ModelReminderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReminders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clientId
        scheduleDelivery
        reminderTimestamp
        sentCount
        currentJourneyInstance
        mobilePhone
        reminderType
        organization
        sentLimit
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const clientbyReminderTimestamp = /* GraphQL */ `
  query ClientbyReminderTimestamp(
    $reminderTimestamp: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientbyReminderTimestamp(
      reminderTimestamp: $reminderTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        scheduleDelivery
        reminderTimestamp
        sentCount
        currentJourneyInstance
        mobilePhone
        reminderType
        organization
        sentLimit
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGame = /* GraphQL */ `
  query GetGame($id: ID!) {
    getGame(id: $id) {
      id
      name
      gameType
      gameState
      gameUrl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listGames = /* GraphQL */ `
  query ListGames(
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        gameType
        gameState
        gameUrl
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGames = /* GraphQL */ `
  query SyncGames(
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGames(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        gameType
        gameState
        gameUrl
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGameInstances = /* GraphQL */ `
  query GetGameInstances($id: ID!) {
    getGameInstances(id: $id) {
      id
      gameId
      score
      highScore
      gameInfo
      gamename
      lives
      life
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listGameInstances = /* GraphQL */ `
  query ListGameInstances(
    $filter: ModelGameInstancesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGameInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        gameId
        score
        highScore
        gameInfo
        gamename
        lives
        life
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGameInstances = /* GraphQL */ `
  query SyncGameInstances(
    $filter: ModelGameInstancesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGameInstances(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        gameId
        score
        highScore
        gameInfo
        gamename
        lives
        life
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCampaign = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      organization
      journeys {
        nextToken
        startedAt
        __typename
      }
      name
      inviteText
      inviteURL
      rewardPointConversion
      contactModes
      currentContactModeIndex
      inviteEmail
      themeID
      theme {
        id
        name
        property
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      enabled
      startDate
      endDate
      contactFrequency
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listCampaigns = /* GraphQL */ `
  query ListCampaigns(
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organization
        name
        inviteText
        inviteURL
        rewardPointConversion
        contactModes
        currentContactModeIndex
        inviteEmail
        themeID
        enabled
        startDate
        endDate
        contactFrequency
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCampaigns = /* GraphQL */ `
  query SyncCampaigns(
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCampaigns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        organization
        name
        inviteText
        inviteURL
        rewardPointConversion
        contactModes
        currentContactModeIndex
        inviteEmail
        themeID
        enabled
        startDate
        endDate
        contactFrequency
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const campaignsInOrganization = /* GraphQL */ `
  query CampaignsInOrganization(
    $organization: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignsInOrganization(
      organization: $organization
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organization
        name
        inviteText
        inviteURL
        rewardPointConversion
        contactModes
        currentContactModeIndex
        inviteEmail
        themeID
        enabled
        startDate
        endDate
        contactFrequency
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getJourney = /* GraphQL */ `
  query GetJourney($id: ID!) {
    getJourney(id: $id) {
      id
      organization
      title
      journeyType
      episodes {
        nextToken
        startedAt
        __typename
      }
      displayMode
      imgBanner
      CampaignID
      order
      isBinge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listJourneys = /* GraphQL */ `
  query ListJourneys(
    $filter: ModelJourneyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJourneys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organization
        title
        journeyType
        displayMode
        imgBanner
        CampaignID
        order
        isBinge
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncJourneys = /* GraphQL */ `
  query SyncJourneys(
    $filter: ModelJourneyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncJourneys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        organization
        title
        journeyType
        displayMode
        imgBanner
        CampaignID
        order
        isBinge
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const journeysInCampaign = /* GraphQL */ `
  query JourneysInCampaign(
    $CampaignID: ID!
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJourneyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    journeysInCampaign(
      CampaignID: $CampaignID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organization
        title
        journeyType
        displayMode
        imgBanner
        CampaignID
        order
        isBinge
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEpisode = /* GraphQL */ `
  query GetEpisode($id: ID!) {
    getEpisode(id: $id) {
      id
      organization
      episodeType
      title
      subTitle
      episodeImage
      episodeLockImage
      quizs {
        nextToken
        startedAt
        __typename
      }
      quizDependency {
        nextToken
        __typename
      }
      logicRules
      levelBGImage
      levelBGLockImage
      displayMode
      landingTitle
      landinSubtitle
      landingImage
      journeyID
      order
      isReleased
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listEpisodes = /* GraphQL */ `
  query ListEpisodes(
    $filter: ModelEpisodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEpisodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organization
        episodeType
        title
        subTitle
        episodeImage
        episodeLockImage
        logicRules
        levelBGImage
        levelBGLockImage
        displayMode
        landingTitle
        landinSubtitle
        landingImage
        journeyID
        order
        isReleased
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEpisodes = /* GraphQL */ `
  query SyncEpisodes(
    $filter: ModelEpisodeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEpisodes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        organization
        episodeType
        title
        subTitle
        episodeImage
        episodeLockImage
        logicRules
        levelBGImage
        levelBGLockImage
        displayMode
        landingTitle
        landinSubtitle
        landingImage
        journeyID
        order
        isReleased
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const episodesInJourney = /* GraphQL */ `
  query EpisodesInJourney(
    $journeyID: ID!
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEpisodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    episodesInJourney(
      journeyID: $journeyID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organization
        episodeType
        title
        subTitle
        episodeImage
        episodeLockImage
        logicRules
        levelBGImage
        levelBGLockImage
        displayMode
        landingTitle
        landinSubtitle
        landingImage
        journeyID
        order
        isReleased
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getQuiz = /* GraphQL */ `
  query GetQuiz($id: ID!) {
    getQuiz(id: $id) {
      id
      organization
      label
      note
      placeholder
      quizType
      quizContentType
      correctOption
      options {
        nextToken
        startedAt
        __typename
      }
      answerOptions
      imgBanner
      episodeID
      order
      visibleIf
      triggers
      personalityAnalysis
      metaData
      isRequired
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listQuizzes = /* GraphQL */ `
  query ListQuizzes(
    $filter: ModelQuizFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizzes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organization
        label
        note
        placeholder
        quizType
        quizContentType
        correctOption
        answerOptions
        imgBanner
        episodeID
        order
        visibleIf
        triggers
        personalityAnalysis
        metaData
        isRequired
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncQuizzes = /* GraphQL */ `
  query SyncQuizzes(
    $filter: ModelQuizFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQuizzes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        organization
        label
        note
        placeholder
        quizType
        quizContentType
        correctOption
        answerOptions
        imgBanner
        episodeID
        order
        visibleIf
        triggers
        personalityAnalysis
        metaData
        isRequired
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const quizzesInEpisode = /* GraphQL */ `
  query QuizzesInEpisode(
    $episodeID: ID!
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuizFilterInput
    $limit: Int
    $nextToken: String
  ) {
    quizzesInEpisode(
      episodeID: $episodeID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organization
        label
        note
        placeholder
        quizType
        quizContentType
        correctOption
        answerOptions
        imgBanner
        episodeID
        order
        visibleIf
        triggers
        personalityAnalysis
        metaData
        isRequired
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getQuizDependency = /* GraphQL */ `
  query GetQuizDependency($id: ID!) {
    getQuizDependency(id: $id) {
      id
      dependencyType
      dependentQuizId
      isShowTextCondition
      conditions
      episodeID
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listQuizDependencies = /* GraphQL */ `
  query ListQuizDependencies(
    $filter: ModelQuizDependencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizDependencies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dependencyType
        dependentQuizId
        isShowTextCondition
        conditions
        episodeID
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncQuizDependencies = /* GraphQL */ `
  query SyncQuizDependencies(
    $filter: ModelQuizDependencyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQuizDependencies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        dependencyType
        dependentQuizId
        isShowTextCondition
        conditions
        episodeID
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const quizDependenciesInEpisode = /* GraphQL */ `
  query QuizDependenciesInEpisode(
    $episodeID: ID!
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelquizDependencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    quizDependenciesInEpisode(
      episodeID: $episodeID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dependencyType
        dependentQuizId
        isShowTextCondition
        conditions
        episodeID
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuizOption = /* GraphQL */ `
  query GetQuizOption($id: ID!) {
    getQuizOption(id: $id) {
      id
      label
      value
      order
      metaData
      quizID
      quizOptionTheme {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listQuizOptions = /* GraphQL */ `
  query ListQuizOptions(
    $filter: ModelQuizOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        value
        order
        metaData
        quizID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncQuizOptions = /* GraphQL */ `
  query SyncQuizOptions(
    $filter: ModelQuizOptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQuizOptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        label
        value
        order
        metaData
        quizID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const quizOptionsInQuiz = /* GraphQL */ `
  query QuizOptionsInQuiz(
    $quizID: ID!
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuizOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    quizOptionsInQuiz(
      quizID: $quizID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        value
        order
        metaData
        quizID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getQuizOptionTheme = /* GraphQL */ `
  query GetQuizOptionTheme($id: ID!) {
    getQuizOptionTheme(id: $id) {
      id
      quizOptionID
      theme
      url
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listQuizOptionThemes = /* GraphQL */ `
  query ListQuizOptionThemes(
    $filter: ModelQuizOptionThemeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizOptionThemes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quizOptionID
        theme
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncQuizOptionThemes = /* GraphQL */ `
  query SyncQuizOptionThemes(
    $filter: ModelQuizOptionThemeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQuizOptionThemes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        quizOptionID
        theme
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const quizOptionThemesInQuizOption = /* GraphQL */ `
  query QuizOptionThemesInQuizOption(
    $quizOptionID: ID!
    $theme: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuizOptionThemeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    quizOptionThemesInQuizOption(
      quizOptionID: $quizOptionID
      theme: $theme
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quizOptionID
        theme
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTheme = /* GraphQL */ `
  query GetTheme($id: ID!) {
    getTheme(id: $id) {
      id
      name
      property
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listThemes = /* GraphQL */ `
  query ListThemes(
    $filter: ModelThemeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThemes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        property
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncThemes = /* GraphQL */ `
  query SyncThemes(
    $filter: ModelThemeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncThemes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        property
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
