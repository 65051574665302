import { notificationActionTypes } from "./constants";

const initialState = {};

const notification = (state = initialState, action) => {
    switch(action.type){
        case notificationActionTypes.SET_NOTIFICATION:
            return action.payload
        default: return state
    }
}

export default notification;

