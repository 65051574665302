// @flow
import { combineReducers } from 'redux';
import Layout from './layout/reducers';
import theme from './theme/reducers';
import notification from './notification/reducers';

export default (combineReducers({
    Layout,
    theme,
    notification
}): any);
