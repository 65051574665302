// @flow
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Routes from './routes/Routes';
// For Creative demo import Creative.scss
import './assets/scss/Creative.scss';
import awsExports from './aws-exports';
import { Hub, Logger } from 'aws-amplify';
import {AmazonAIPredictionsProvider, Predictions} from '@aws-amplify/predictions';

Amplify.configure({
  ...awsExports, 
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: process.env.REACT_APP_SYNC_API_KEY,
});

Amplify.register(Predictions);
Predictions.addPluggable(new AmazonAIPredictionsProvider());
// console.log("awsExports:", awsExports);

// const AddLogOutIfLoggedIn = ({ user: User, onclickFnx:OnClickFnx }) => {
//    if (User.isUserAuthenticated) { 
//     return( <button onClick={OnClickFnx}>Sign Out</button> );
//    }
// }

// function App({ isPassedToWithAuthenticator, signOut, user }) {
//   if (!isPassedToWithAuthenticator) {
//   console.log("isPassedToWithAuthenticator was not provided");
// //    throw new Error(`isPassedToWithAuthenticator was not provided`);
//   }
//   console.log("App");

//   return (
//     <>
//       <Routes></Routes>
//       <AddLogOutIfLoggedIn user={user} onclickFnx={signOut} />
//     </>
//   )

// }

// export default withAuthenticator(App, true);

function App() {
  return (
    <div>
      <Authenticator.Provider>
        <Routes/>
      </Authenticator.Provider>
    </div>
  )

}
export default (App)

// function AppHelper() {
//   useEffect(() => {
//     return onAuthUIStateChange((state, data) => {
//       console.log("AppHelper useEffect UIStateCHange state:", state + "\ndata:" + data);
//       //add your logic
//     });
//   }, []);
// }



export async function getStaticProps() {
  return {
    props: {
      isPassedToWithAuthenticator: true,
    },
  };
}

// log user state changes
const logger = new Logger('My-Logger');
const useListener = (data) => {
  console.log("useListener: data", data);
  switch (data.payload.event) {
    case 'signIn':
      logger.info('user signed in');
      console.log('user signed in');
      break;
    case 'signUp':
      logger.info('user signed up');
      break;
    case 'signOut':
      logger.info('user signed out');
      console.log('user signed out');
      break;
    case 'signIn_failure':
      logger.error('user sign in failed');
      break;
    case 'tokenRefresh':
      logger.info('token refresh succeeded');
      break;
    case 'tokenRefresh_failure':
      logger.error('token refresh failed');
      break;
    case 'configured':
      logger.info('the Auth module is configured');
      break;
      default:
      logger.info('This is a default case');
  }
}

Hub.listen('auth', useListener);