// @flow
// noinspection JSUnresolvedVariable

import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateRoute from "./PrivateRoute";
// import PublicRoute from "./PublicRoute";
import Root from "./Root";
import * as layoutConstants from "../constants/layout";

// All layouts/containers
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";
import DetachedLayout from "../layouts/Detached";
import HorizontalLayout from "../layouts/Horizontal";
import FullLayout from "../layouts/Full";
import MapLanding from "../pages/arena-map-now/MapLanding";

// lazy load all the views

// auth
// const ArenaLogin = React.lazy(() => import('../pages/arena-authentication/ArenaLogin'));
const ArenaAuthentication = React.lazy(() =>
  import("../pages/arena-authentication/ArenaAuthentication")
);
// const ArenaLanding = React.lazy(() =>
//   import("../pages/arena-landing/ArenaLanding")
// );
const ArenaEpisodes = React.lazy(() =>
  import("../pages/arena-journey/Episodes")
);
const ArenaJourneys = React.lazy(() =>
  import("../pages/arena-journey/Journeys")
);
const ArenaTouchPoint = React.lazy(() =>
  import("../pages/arena-touch-point/TouchPoints")
);
// const ArenaTouchPointLanding = React.lazy(() => import('../pages/arena-touch-point/TouchPointLanding[id]'));
const ArenaEpisodeLanding = React.lazy(() =>
  import("../components/Arena/EpisodeLanding")
);
// const EpisodeQuiz = React.lazy(() => import('../pages/arena-touch-point/TouchPointQuiz[id]'));
const EpisodeQuiz = React.lazy(() =>
  import("../pages/arena-journey/EpisodeQuiz")
);
const Services = React.lazy(() => import("../pages/dashboard/Services"));
const QrScanner = React.lazy(() => import("../pages/arena-journey/QrScanner"));
const ScoreLanding = React.lazy(() =>
  import("../pages/arena-touch-point/ScoreLanding")
);
const MapNow = React.lazy(() => import("../pages/arena-map-now/MapNow"));
const Login = React.lazy(() => import("../pages/account/Login"));
const Logout = React.lazy(() => import("../pages/account/Logout"));
const Game = React.lazy(() => import("../pages/arena-games/Game"));
const Summary = React.lazy(() => import("../pages/arena-summary/Summary"));
const RedeemCoupon = React.lazy(() =>
  import("../pages/arena-summary/RedeemCoupon")
);
//const Register = React.lazy(() => import('../pages/account/Register'));
//const Confirm = React.lazy(() => import('../pages/account/Confirm'));
//const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));
const LockScreen = React.lazy(() => import("../pages/account/LockScreen"));

//const Login2 = React.lazy(() => import('../pages/account2/Login2'));
//const Logout2 = React.lazy(() => import('../pages/account2/Logout2'));
//const Register2 = React.lazy(() => import('../pages/account2/Register2'));
//const Confirm2 = React.lazy(() => import('../pages/account2/Confirm2'));
//const ForgetPassword2 = React.lazy(() => import('../pages/account2/ForgetPassword2'));
const LockScreen2 = React.lazy(() => import("../pages/account2/LockScreen2"));

// dashboard
const AnalyticsDashboard = React.lazy(() =>
  import("../pages/dashboard/Analytics")
);
const OrganizationsDashboard = React.lazy(() =>
  import("../pages/dashboard/Organizations")
);
const CampaignsDashboard = React.lazy(() =>
  import("../pages/dashboard/Campaigns")
);
const JourneysDashboard = React.lazy(() =>
  import("../pages/dashboard/Journeys")
);
const EpisodesDashboard = React.lazy(() =>
  import("../pages/dashboard/Episodes")
);
const ClinicianDashboard = React.lazy(() =>
  import("../pages/dashboard/Clinician")
);
const QuizzesDashboard = React.lazy(() => import("../pages/dashboard/Quizzes"));
// const EcommerceDashboard = React.lazy(() =>
//   import("../pages/dashboard/Ecommerce")
// );
// const ProjectDashboard = React.lazy(() => import("../pages/dashboard/Project"));
const GamesDashboard = React.lazy(() => import("../pages/dashboard/Games"));
const ClientsDashboard = React.lazy(() => import("../pages/dashboard/Clients"));
const FileManager = React.lazy(() => import("../pages/dashboard/FileManager"));
const Gallery = React.lazy(() => import("../pages/dashboard/Gallery"));
// apps
// const CalendarApp = React.lazy(() => import("../pages/apps/Calendar"));
// const Projects = React.lazy(() => import("../pages/apps/Projects"));
// const ProjectDetail = React.lazy(() => import("../pages/apps/Projects/Detail"));
// const ProjectGannt = React.lazy(() => import("../pages/apps/Projects/Gantt"));
// const ProjectForm = React.lazy(() =>
//   import("../pages/apps/Projects/ProjectForm")
// );

// - chat
//const ChatApp = React.lazy(() => import('../pages/apps/Chat'));

// - email
/*
const Inbox = React.lazy(() => import('../pages/apps/Email/Inbox'));
const EmailDetail = React.lazy(() => import('../pages/apps/Email/Detail'));
*/
// - social
// const SocialFeed = React.lazy(() => import("../pages/apps/SocialFeed"));

// - file
//const FileManager = React.lazy(() => import('../pages/apps/FileManager'));

// pages
// const Profile = React.lazy(() => import("../pages/profile"));
// const Profile2 = React.lazy(() => import("../pages/profile2"));
const ErrorPageNotFound = React.lazy(() =>
  import("../pages/error/PageNotFound")
);
const ErrorPageNotFoundAlt = React.lazy(() =>
  import("../pages/error/PageNotFoundAlt")
);
const AccessDenied = React.lazy(() => import("../pages/error/AccessDenied"));
const ServerError = React.lazy(() => import("../pages/error/ServerError"));

// - other
const FAQ = React.lazy(() => import("../pages/other/FAQ"));

const Maintenance = React.lazy(() => import("../pages/other/Maintenance"));
const Starter = React.lazy(() => import("../pages/other/Starter"));
// const PreLoader = React.lazy(() => import("../pages/other/PreLoader"));
// const Timeline = React.lazy(() => import("../pages/other/Timeline"));

const Landing = React.lazy(() => import("../pages/landing"));

// uikit
const Accordions = React.lazy(() => import("../pages/uikit/Accordions"));
const Alerts = React.lazy(() => import("../pages/uikit/Alerts"));
const Avatars = React.lazy(() => import("../pages/uikit/Avatars"));
const Badges = React.lazy(() => import("../pages/uikit/Badges"));
const Breadcrumbs = React.lazy(() => import("../pages/uikit/Breadcrumb"));
const Buttons = React.lazy(() => import("../pages/uikit/Buttons"));
const Cards = React.lazy(() => import("../pages/uikit/Cards"));
const Carousels = React.lazy(() => import("../pages/uikit/Carousel"));
const Dropdowns = React.lazy(() => import("../pages/uikit/Dropdowns"));
const EmbedVideo = React.lazy(() => import("../pages/uikit/EmbedVideo"));
const Grid = React.lazy(() => import("../pages/uikit/Grid"));
const ListGroups = React.lazy(() => import("../pages/uikit/ListGroups"));
const Modals = React.lazy(() => import("../pages/uikit/Modals"));
const Notifications = React.lazy(() => import("../pages/uikit/Notifications"));
const Offcanvases = React.lazy(() => import("../pages/uikit/Offcanvas"));
const Paginations = React.lazy(() => import("../pages/uikit/Paginations"));
const Popovers = React.lazy(() => import("../pages/uikit/Popovers"));
const Progress = React.lazy(() => import("../pages/uikit/Progress"));
const Ribbons = React.lazy(() => import("../pages/uikit/Ribbons"));
const Spinners = React.lazy(() => import("../pages/uikit/Spinners"));
const Tabs = React.lazy(() => import("../pages/uikit/Tabs"));
const Tooltips = React.lazy(() => import("../pages/uikit/Tooltips"));
const Typography = React.lazy(() => import("../pages/uikit/Typography"));
const DragDrop = React.lazy(() => import("../pages/uikit/DragDrop"));
const RangeSliders = React.lazy(() => import("../pages/uikit/RangeSliders"));
const Ratings = React.lazy(() => import("../pages/uikit/Ratings"));

// icons
const Dripicons = React.lazy(() => import("../pages/icons/Dripicons"));
const MDIIcons = React.lazy(() => import("../pages/icons/MDIIcons"));
const Unicons = React.lazy(() => import("../pages/icons/Unicons"));

// forms
const BasicForms = React.lazy(() => import("../pages/forms/Basic"));
const FormAdvanced = React.lazy(() => import("../pages/forms/Advanced"));
const FormValidation = React.lazy(() => import("../pages/forms/Validation"));
const FormWizard = React.lazy(() => import("../pages/forms/Wizard"));
const FileUpload = React.lazy(() => import("../pages/forms/FileUpload"));
const Editors = React.lazy(() => import("../pages/forms/Editors"));

// charts
const ApexChart = React.lazy(() => import("../pages/charts/Apex"));
const BriteChart = React.lazy(() => import("../pages/charts/Brite"));
const ChartJs = React.lazy(() => import("../pages/charts/ChartJs"));

// tables
const BasicTables = React.lazy(() => import("../pages/tables/Basic"));
const AdvancedTables = React.lazy(() => import("../pages/tables/Advanced"));

// widgets
const Widgets = React.lazy(() => import("../pages/uikit/Widgets"));

// maps
const GoogleMaps = React.lazy(() => import("../pages/maps/GoogleMaps"));
//const VectorMaps = React.lazy(() => import('../pages/maps/VectorMaps'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
  component: React.LazyExoticComponent<() => JSX.Element>,
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
  <Suspense fallback={loading()}>
    <Component />
  </Suspense>
);

const AllRoutes = () => {
  const { layout } = useSelector((state) => ({
    layout: state.Layout,
  }));

  const getLayout = () => {
    let layoutCls = VerticalLayout;
    switch (layout.layoutType) {
      case layoutConstants.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case layoutConstants.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case layoutConstants.LAYOUT_FULL:
        layoutCls = FullLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  let Layout = getLayout();

  return useRoutes([
    { path: "/", element: <Root /> },
    {
      // public routes
      path: "/",
      element: <DefaultLayout />,
      children: [
        {
          path: "login",
          element: <LoadComponent component={Login} />,
        },
        {
          path: "account",
          children: [
            //    { path: 'login', element: <LoadComponent component={Login} /> },
            //                        { path: 'register', element: <LoadComponent component={Register} /> },
            //                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
            //                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
            {
              path: "lock-screen",
              element: <LoadComponent component={LockScreen} />,
            },
            { path: "logout", element: <LoadComponent component={Logout} /> },
            //                        { path: 'login2', element: <LoadComponent component={Login2} /> },
            //                        { path: 'register2', element: <LoadComponent component={Register2} /> },
            //                        { path: 'confirm2', element: <LoadComponent component={Confirm2} /> },
            //                        { path: 'forget-password2', element: <LoadComponent component={ForgetPassword2} /> },
            {
              path: "lock-screen2",
              element: <LoadComponent component={LockScreen2} />,
            },
            //                       { path: 'logout2', element: <LoadComponent component={Logout2} /> },
          ],
        },
        {
          path: "arena",
          children: [
            {
              path: "login",
              element: <LoadComponent component={ArenaAuthentication} />,
            },
          ],
        },
        // {
        //   path: "arena-landing",
        //   element: <LoadComponent component={ArenaLanding} />,
        // },
        {
          path: "journeys",
          element: <LoadComponent component={ArenaJourneys} />,
        },
        {
          path: "episodes",
          element: <LoadComponent component={ArenaEpisodes} />,
        },
        {
          path: "touch-point/:id",
          element: <LoadComponent component={ArenaTouchPoint} />,
        },
        // {
        //     path:'touch-point-landing/:touchPoint',
        //     element: <LoadComponent component={ArenaTouchPointLanding} />
        // },
        {
          path: "episode-landing",
          element: <LoadComponent component={ArenaEpisodeLanding} />,
        },
        {
          path: "quiz",
          element: <LoadComponent component={EpisodeQuiz} />,
        },
        {
          path: "map-now",
          element: <LoadComponent component={MapNow} />,
        },
        {
          path: "map-landing",
          element: <LoadComponent component={MapLanding} />,
        },
        {
          path: "landing",
          element: <LoadComponent component={Landing} />,
        },
        {
          path: "score-landing",
          element: <LoadComponent component={ScoreLanding} />,
        },
        {
          path: "scanner",
          element: <LoadComponent component={QrScanner} />,
        },
        {
          path: "game",
          element: <LoadComponent component={Game} />,
        },
        {
          path: "summary",
          element: <LoadComponent component={Summary} />,
        },
        {
          path: "coupon",
          element: <LoadComponent component={RedeemCoupon} />,
        },
        {
          path: "error-404",
          element: <LoadComponent component={ErrorPageNotFound} />,
        },
        {
          path: "error-500",
          element: <LoadComponent component={ServerError} />,
        },
        {
          path: "maintenance",
          element: <LoadComponent component={Maintenance} />,
        },
        {
          path: "access-denied",
          element: <LoadComponent component={AccessDenied} />,
        },
      ],
    },
    {
      // auth protected routes
      path: "/",
      element: (
        <PrivateRoute
          roles={[
            "Clients",
            "Administrators",
            "OrganizationAdministrators",
            "Clinician",
          ]}
          component={Layout}
        />
      ),
      children: [
        {
          path: "dashboard",
          children: [
            {
              path: "clients",
              element: <LoadComponent component={ClientsDashboard} />,
            },
            {
              path: "403",
              element: <LoadComponent component={ErrorPageNotFoundAlt} />,
            },
          ],
        },
      ],
    },
    {
      // auth protected routes
      path: "/",
      element: <PrivateRoute roles={["Administrators"]} component={Layout} />,
      children: [
        {
          path: "dashboard",
          children: [
            {
              path: "Gallery",
              element: <LoadComponent component={Gallery} />,
            },
          ],
        },
      ],
    },
    {
      // auth protected routes
      path: "/",
      element: (
        <PrivateRoute
          roles={["Administrators", "OrganizationAdministrators"]}
          component={Layout}
        />
      ),
      children: [
        {
          path: "dashboard",
          children: [
            {
              path: "games",
              element: <LoadComponent component={GamesDashboard} />,
            },
            {
              path: "analytics",
              element: <LoadComponent component={AnalyticsDashboard} />,
            },
            {
              path: "organizations",
              element: <LoadComponent component={OrganizationsDashboard} />,
            },
            // {
            //   path: 'clients',
            //   element: <LoadComponent component={ClientsDashboard} />,
            // },
            {
              path: "journeys",
              element: <LoadComponent component={JourneysDashboard} />,
            },
            {
              path: "campaigns",
              element: <LoadComponent component={CampaignsDashboard} />,
            },
            {
              path: "episodes",
              element: <LoadComponent component={EpisodesDashboard} />,
            },
            {
              path: "quizzes",
              element: <LoadComponent component={QuizzesDashboard} />,
            },
            {
              path: "filemanager",
              element: <LoadComponent component={FileManager} />,
            },
            {
              path: "clinician",
              element: <LoadComponent component={ClinicianDashboard} />,
            },
            {
              path: "services",
              element: <LoadComponent component={Services} />,
            },
            // {
            //   path: '403',
            //   element: <LoadComponent component={ErrorPageNotFoundAlt} />,
            // },
          ],
        },
        {
          path: "pages",
          children: [
            {
              path: "starter",
              element: <LoadComponent component={Starter} />,
            },
            /*
                        {
                            path: 'profile',
                            element: <LoadComponent component={Profile} />,
                        },
                        {
                            path: 'profile2',
                            element: <LoadComponent component={Profile2} />,
                        },
                        {
                            path: 'pricing',
                            element: <LoadComponent component={Pricing} />,
                        },
                        {
                            path: 'error-404-alt',
                            element: <LoadComponent component={ErrorPageNotFoundAlt} />,
                        },
                        {
                            path: 'timeline',
                            element: <LoadComponent component={Timeline} />,
                        },
                        {
                            path: 'invoice',
                            element: <LoadComponent component={Invoice} />,
                        },

 */
            {
              path: "faq",
              element: <LoadComponent component={FAQ} />,
            },
            /*
                        {
                            path: 'preloader',
                            element: <LoadComponent component={PreLoader} />,
                        },
 */
          ],
        },
        {
          path: "ui",
          children: [
            {
              path: "base-ui",
              children: [
                {
                  path: "accordions",
                  element: <LoadComponent component={Accordions} />,
                },
                {
                  path: "alerts",
                  element: <LoadComponent component={Alerts} />,
                },
                {
                  path: "avatars",
                  element: <LoadComponent component={Avatars} />,
                },
                {
                  path: "badges",
                  element: <LoadComponent component={Badges} />,
                },
                {
                  path: "breadcrumb",
                  element: <LoadComponent component={Breadcrumbs} />,
                },
                {
                  path: "buttons",
                  element: <LoadComponent component={Buttons} />,
                },
                {
                  path: "cards",
                  element: <LoadComponent component={Cards} />,
                },
                {
                  path: "carousel",
                  element: <LoadComponent component={Carousels} />,
                },
                {
                  path: "dropdowns",
                  element: <LoadComponent component={Dropdowns} />,
                },
                {
                  path: "embedvideo",
                  element: <LoadComponent component={EmbedVideo} />,
                },
                {
                  path: "grid",
                  element: <LoadComponent component={Grid} />,
                },
                {
                  path: "listgroups",
                  element: <LoadComponent component={ListGroups} />,
                },
                {
                  path: "modals",
                  element: <LoadComponent component={Modals} />,
                },
                {
                  path: "notifications",
                  element: <LoadComponent component={Notifications} />,
                },
                {
                  path: "offcanvas",
                  element: <LoadComponent component={Offcanvases} />,
                },
                {
                  path: "paginations",
                  element: <LoadComponent component={Paginations} />,
                },
                // {
                //     path: 'placeholders',
                //     element: <LoadComponent component={Placeholders} />,
                // },
                {
                  path: "popovers",
                  element: <LoadComponent component={Popovers} />,
                },
                {
                  path: "progress",
                  element: <LoadComponent component={Progress} />,
                },
                {
                  path: "ribbons",
                  element: <LoadComponent component={Ribbons} />,
                },
                {
                  path: "spinners",
                  element: <LoadComponent component={Spinners} />,
                },
                {
                  path: "tabs",
                  element: <LoadComponent component={Tabs} />,
                },
                {
                  path: "tooltips",
                  element: <LoadComponent component={Tooltips} />,
                },
                {
                  path: "typography",
                  element: <LoadComponent component={Typography} />,
                },
              ],
            },
            {
              path: "widgets",
              element: <LoadComponent component={Widgets} />,
            },
            {
              path: "extended",
              children: [
                {
                  path: "dragdrop",
                  element: <LoadComponent component={DragDrop} />,
                },
                {
                  path: "rangesliders",
                  element: <LoadComponent component={RangeSliders} />,
                },
                {
                  path: "ratings",
                  element: <LoadComponent component={Ratings} />,
                },
              ],
            },
            {
              path: "icons",
              children: [
                {
                  path: "unicons",
                  element: <LoadComponent component={Unicons} />,
                },
                {
                  path: "mdi",
                  element: <LoadComponent component={MDIIcons} />,
                },
                {
                  path: "dripicons",
                  element: <LoadComponent component={Dripicons} />,
                },
              ],
            },
            {
              path: "forms",
              children: [
                {
                  path: "basic",
                  element: <LoadComponent component={BasicForms} />,
                },
                {
                  path: "advanced",
                  element: <LoadComponent component={FormAdvanced} />,
                },
                {
                  path: "validation",
                  element: <LoadComponent component={FormValidation} />,
                },
                {
                  path: "wizard",
                  element: <LoadComponent component={FormWizard} />,
                },
                {
                  path: "upload",
                  element: <LoadComponent component={FileUpload} />,
                },
                {
                  path: "editors",
                  element: <LoadComponent component={Editors} />,
                },
              ],
            },
            {
              path: "tables",
              children: [
                {
                  path: "basic",
                  element: <LoadComponent component={BasicTables} />,
                },
                {
                  path: "advanced",
                  element: <LoadComponent component={AdvancedTables} />,
                },
              ],
            },
            {
              path: "charts",
              children: [
                {
                  path: "apex",
                  element: <LoadComponent component={ApexChart} />,
                },
                {
                  path: "brite",
                  element: <LoadComponent component={BriteChart} />,
                },
                {
                  path: "chartjs",
                  element: <LoadComponent component={ChartJs} />,
                },
              ],
            },
            {
              path: "maps",
              children: [
                {
                  path: "googlemaps",
                  element: <LoadComponent component={GoogleMaps} />,
                },
                //                                {
                //                                    path: 'vectormaps',
                //                                    element: <LoadComponent component={VectorMaps} />,
                //                                },
              ],
            },
          ],
        },
      ],
    },
  ]);
};

export { AllRoutes };
