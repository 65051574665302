export const API_ENDPOINT = process.env.REACT_APP_API_URL_V1;
export const sizePerPageList = () => {
  const sizePerPageLists = [
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "50",
      value: 50,
    },
  ];
  return sizePerPageLists;
};
