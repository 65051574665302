/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      description
      S3IngestFileURL
      streetAddress
      city
      state
      zipcode
      clientLogoURL
      status
      campaigns {
        nextToken
        startedAt
        __typename
      }
      contactName
      contactPhone
      contactEmail
      imgBanner
      slogan
      note
      orgState
      services {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      description
      S3IngestFileURL
      streetAddress
      city
      state
      zipcode
      clientLogoURL
      status
      campaigns {
        nextToken
        startedAt
        __typename
      }
      contactName
      contactPhone
      contactEmail
      imgBanner
      slogan
      note
      orgState
      services {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      description
      S3IngestFileURL
      streetAddress
      city
      state
      zipcode
      clientLogoURL
      status
      campaigns {
        nextToken
        startedAt
        __typename
      }
      contactName
      contactPhone
      contactEmail
      imgBanner
      slogan
      note
      orgState
      services {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService(
    $input: CreateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    createService(input: $input, condition: $condition) {
      id
      name
      description
      value
      url
      organization
      thumbnail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService(
    $input: UpdateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    updateService(input: $input, condition: $condition) {
      id
      name
      description
      value
      url
      organization
      thumbnail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService(
    $input: DeleteServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    deleteService(input: $input, condition: $condition) {
      id
      name
      description
      value
      url
      organization
      thumbnail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      firstName
      lastName
      mobilePhone
      email
      clientState
      optInState
      preferredContactMode
      sentCount
      campaign
      currentJourneyInstance
      previousJourneyInstances
      currentEpisodeInstance
      currentQuizInstance
      organization
      affiliation
      rewardPoints
      otherPoints
      streetAddress
      streetAddress2
      city
      state
      zipcode
      sentMessageDetails
      eventDetails
      quizAnswers
      quizPrediction
      cognitoId
      otp
      latestActivity
      currentActivity
      thumbnail
      ClinicianID
      clinicianIDs
      Notes {
        nextToken
        startedAt
        __typename
      }
      files
      services
      personalityStatus
      visitedJourneyEpisodes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      firstName
      lastName
      mobilePhone
      email
      clientState
      optInState
      preferredContactMode
      sentCount
      campaign
      currentJourneyInstance
      previousJourneyInstances
      currentEpisodeInstance
      currentQuizInstance
      organization
      affiliation
      rewardPoints
      otherPoints
      streetAddress
      streetAddress2
      city
      state
      zipcode
      sentMessageDetails
      eventDetails
      quizAnswers
      quizPrediction
      cognitoId
      otp
      latestActivity
      currentActivity
      thumbnail
      ClinicianID
      clinicianIDs
      Notes {
        nextToken
        startedAt
        __typename
      }
      files
      services
      personalityStatus
      visitedJourneyEpisodes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      firstName
      lastName
      mobilePhone
      email
      clientState
      optInState
      preferredContactMode
      sentCount
      campaign
      currentJourneyInstance
      previousJourneyInstances
      currentEpisodeInstance
      currentQuizInstance
      organization
      affiliation
      rewardPoints
      otherPoints
      streetAddress
      streetAddress2
      city
      state
      zipcode
      sentMessageDetails
      eventDetails
      quizAnswers
      quizPrediction
      cognitoId
      otp
      latestActivity
      currentActivity
      thumbnail
      ClinicianID
      clinicianIDs
      Notes {
        nextToken
        startedAt
        __typename
      }
      files
      services
      personalityStatus
      visitedJourneyEpisodes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createClinician = /* GraphQL */ `
  mutation CreateClinician(
    $input: CreateClinicianInput!
    $condition: ModelClinicianConditionInput
  ) {
    createClinician(input: $input, condition: $condition) {
      id
      firstName
      lastName
      mobilePhone
      email
      streetAddress
      streetAddress2
      city
      state
      zipcode
      thumbnail
      organization
      clients {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateClinician = /* GraphQL */ `
  mutation UpdateClinician(
    $input: UpdateClinicianInput!
    $condition: ModelClinicianConditionInput
  ) {
    updateClinician(input: $input, condition: $condition) {
      id
      firstName
      lastName
      mobilePhone
      email
      streetAddress
      streetAddress2
      city
      state
      zipcode
      thumbnail
      organization
      clients {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteClinician = /* GraphQL */ `
  mutation DeleteClinician(
    $input: DeleteClinicianInput!
    $condition: ModelClinicianConditionInput
  ) {
    deleteClinician(input: $input, condition: $condition) {
      id
      firstName
      lastName
      mobilePhone
      email
      streetAddress
      streetAddress2
      city
      state
      zipcode
      thumbnail
      organization
      clients {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      author
      text
      status
      timestamp
      clientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote(
    $input: UpdateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    updateNote(input: $input, condition: $condition) {
      id
      author
      text
      status
      timestamp
      clientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      id
      author
      text
      status
      timestamp
      clientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      text
      clinicianID
      clientID
      client {
        id
        firstName
        lastName
        mobilePhone
        email
        clientState
        optInState
        preferredContactMode
        sentCount
        campaign
        currentJourneyInstance
        previousJourneyInstances
        currentEpisodeInstance
        currentQuizInstance
        organization
        affiliation
        rewardPoints
        otherPoints
        streetAddress
        streetAddress2
        city
        state
        zipcode
        sentMessageDetails
        eventDetails
        quizAnswers
        quizPrediction
        cognitoId
        otp
        latestActivity
        currentActivity
        thumbnail
        ClinicianID
        clinicianIDs
        files
        services
        personalityStatus
        visitedJourneyEpisodes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      status
      timestamp
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      text
      clinicianID
      clientID
      client {
        id
        firstName
        lastName
        mobilePhone
        email
        clientState
        optInState
        preferredContactMode
        sentCount
        campaign
        currentJourneyInstance
        previousJourneyInstances
        currentEpisodeInstance
        currentQuizInstance
        organization
        affiliation
        rewardPoints
        otherPoints
        streetAddress
        streetAddress2
        city
        state
        zipcode
        sentMessageDetails
        eventDetails
        quizAnswers
        quizPrediction
        cognitoId
        otp
        latestActivity
        currentActivity
        thumbnail
        ClinicianID
        clinicianIDs
        files
        services
        personalityStatus
        visitedJourneyEpisodes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      status
      timestamp
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      text
      clinicianID
      clientID
      client {
        id
        firstName
        lastName
        mobilePhone
        email
        clientState
        optInState
        preferredContactMode
        sentCount
        campaign
        currentJourneyInstance
        previousJourneyInstances
        currentEpisodeInstance
        currentQuizInstance
        organization
        affiliation
        rewardPoints
        otherPoints
        streetAddress
        streetAddress2
        city
        state
        zipcode
        sentMessageDetails
        eventDetails
        quizAnswers
        quizPrediction
        cognitoId
        otp
        latestActivity
        currentActivity
        thumbnail
        ClinicianID
        clinicianIDs
        files
        services
        personalityStatus
        visitedJourneyEpisodes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      status
      timestamp
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createReminder = /* GraphQL */ `
  mutation CreateReminder(
    $input: CreateReminderInput!
    $condition: ModelReminderConditionInput
  ) {
    createReminder(input: $input, condition: $condition) {
      id
      clientId
      scheduleDelivery
      reminderTimestamp
      sentCount
      currentJourneyInstance
      mobilePhone
      reminderType
      organization
      sentLimit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateReminder = /* GraphQL */ `
  mutation UpdateReminder(
    $input: UpdateReminderInput!
    $condition: ModelReminderConditionInput
  ) {
    updateReminder(input: $input, condition: $condition) {
      id
      clientId
      scheduleDelivery
      reminderTimestamp
      sentCount
      currentJourneyInstance
      mobilePhone
      reminderType
      organization
      sentLimit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteReminder = /* GraphQL */ `
  mutation DeleteReminder(
    $input: DeleteReminderInput!
    $condition: ModelReminderConditionInput
  ) {
    deleteReminder(input: $input, condition: $condition) {
      id
      clientId
      scheduleDelivery
      reminderTimestamp
      sentCount
      currentJourneyInstance
      mobilePhone
      reminderType
      organization
      sentLimit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createGame = /* GraphQL */ `
  mutation CreateGame(
    $input: CreateGameInput!
    $condition: ModelGameConditionInput
  ) {
    createGame(input: $input, condition: $condition) {
      id
      name
      gameType
      gameState
      gameUrl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateGame = /* GraphQL */ `
  mutation UpdateGame(
    $input: UpdateGameInput!
    $condition: ModelGameConditionInput
  ) {
    updateGame(input: $input, condition: $condition) {
      id
      name
      gameType
      gameState
      gameUrl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteGame = /* GraphQL */ `
  mutation DeleteGame(
    $input: DeleteGameInput!
    $condition: ModelGameConditionInput
  ) {
    deleteGame(input: $input, condition: $condition) {
      id
      name
      gameType
      gameState
      gameUrl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createGameInstances = /* GraphQL */ `
  mutation CreateGameInstances(
    $input: CreateGameInstancesInput!
    $condition: ModelGameInstancesConditionInput
  ) {
    createGameInstances(input: $input, condition: $condition) {
      id
      gameId
      score
      highScore
      gameInfo
      gamename
      lives
      life
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateGameInstances = /* GraphQL */ `
  mutation UpdateGameInstances(
    $input: UpdateGameInstancesInput!
    $condition: ModelGameInstancesConditionInput
  ) {
    updateGameInstances(input: $input, condition: $condition) {
      id
      gameId
      score
      highScore
      gameInfo
      gamename
      lives
      life
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteGameInstances = /* GraphQL */ `
  mutation DeleteGameInstances(
    $input: DeleteGameInstancesInput!
    $condition: ModelGameInstancesConditionInput
  ) {
    deleteGameInstances(input: $input, condition: $condition) {
      id
      gameId
      score
      highScore
      gameInfo
      gamename
      lives
      life
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCampaign = /* GraphQL */ `
  mutation CreateCampaign(
    $input: CreateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    createCampaign(input: $input, condition: $condition) {
      id
      organization
      journeys {
        nextToken
        startedAt
        __typename
      }
      name
      inviteText
      inviteURL
      rewardPointConversion
      contactModes
      currentContactModeIndex
      inviteEmail
      themeID
      theme {
        id
        name
        property
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      enabled
      startDate
      endDate
      contactFrequency
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateCampaign = /* GraphQL */ `
  mutation UpdateCampaign(
    $input: UpdateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    updateCampaign(input: $input, condition: $condition) {
      id
      organization
      journeys {
        nextToken
        startedAt
        __typename
      }
      name
      inviteText
      inviteURL
      rewardPointConversion
      contactModes
      currentContactModeIndex
      inviteEmail
      themeID
      theme {
        id
        name
        property
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      enabled
      startDate
      endDate
      contactFrequency
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteCampaign = /* GraphQL */ `
  mutation DeleteCampaign(
    $input: DeleteCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    deleteCampaign(input: $input, condition: $condition) {
      id
      organization
      journeys {
        nextToken
        startedAt
        __typename
      }
      name
      inviteText
      inviteURL
      rewardPointConversion
      contactModes
      currentContactModeIndex
      inviteEmail
      themeID
      theme {
        id
        name
        property
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      enabled
      startDate
      endDate
      contactFrequency
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createJourney = /* GraphQL */ `
  mutation CreateJourney(
    $input: CreateJourneyInput!
    $condition: ModelJourneyConditionInput
  ) {
    createJourney(input: $input, condition: $condition) {
      id
      organization
      title
      journeyType
      episodes {
        nextToken
        startedAt
        __typename
      }
      displayMode
      imgBanner
      CampaignID
      order
      isBinge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateJourney = /* GraphQL */ `
  mutation UpdateJourney(
    $input: UpdateJourneyInput!
    $condition: ModelJourneyConditionInput
  ) {
    updateJourney(input: $input, condition: $condition) {
      id
      organization
      title
      journeyType
      episodes {
        nextToken
        startedAt
        __typename
      }
      displayMode
      imgBanner
      CampaignID
      order
      isBinge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteJourney = /* GraphQL */ `
  mutation DeleteJourney(
    $input: DeleteJourneyInput!
    $condition: ModelJourneyConditionInput
  ) {
    deleteJourney(input: $input, condition: $condition) {
      id
      organization
      title
      journeyType
      episodes {
        nextToken
        startedAt
        __typename
      }
      displayMode
      imgBanner
      CampaignID
      order
      isBinge
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createEpisode = /* GraphQL */ `
  mutation CreateEpisode(
    $input: CreateEpisodeInput!
    $condition: ModelEpisodeConditionInput
  ) {
    createEpisode(input: $input, condition: $condition) {
      id
      organization
      episodeType
      title
      subTitle
      episodeImage
      episodeLockImage
      quizs {
        nextToken
        startedAt
        __typename
      }
      quizDependency {
        nextToken
        __typename
      }
      logicRules
      levelBGImage
      levelBGLockImage
      displayMode
      landingTitle
      landinSubtitle
      landingImage
      journeyID
      order
      isReleased
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateEpisode = /* GraphQL */ `
  mutation UpdateEpisode(
    $input: UpdateEpisodeInput!
    $condition: ModelEpisodeConditionInput
  ) {
    updateEpisode(input: $input, condition: $condition) {
      id
      organization
      episodeType
      title
      subTitle
      episodeImage
      episodeLockImage
      quizs {
        nextToken
        startedAt
        __typename
      }
      quizDependency {
        nextToken
        __typename
      }
      logicRules
      levelBGImage
      levelBGLockImage
      displayMode
      landingTitle
      landinSubtitle
      landingImage
      journeyID
      order
      isReleased
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteEpisode = /* GraphQL */ `
  mutation DeleteEpisode(
    $input: DeleteEpisodeInput!
    $condition: ModelEpisodeConditionInput
  ) {
    deleteEpisode(input: $input, condition: $condition) {
      id
      organization
      episodeType
      title
      subTitle
      episodeImage
      episodeLockImage
      quizs {
        nextToken
        startedAt
        __typename
      }
      quizDependency {
        nextToken
        __typename
      }
      logicRules
      levelBGImage
      levelBGLockImage
      displayMode
      landingTitle
      landinSubtitle
      landingImage
      journeyID
      order
      isReleased
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createQuiz = /* GraphQL */ `
  mutation CreateQuiz(
    $input: CreateQuizInput!
    $condition: ModelQuizConditionInput
  ) {
    createQuiz(input: $input, condition: $condition) {
      id
      organization
      label
      note
      placeholder
      quizType
      quizContentType
      correctOption
      options {
        nextToken
        startedAt
        __typename
      }
      answerOptions
      imgBanner
      episodeID
      order
      visibleIf
      triggers
      personalityAnalysis
      metaData
      isRequired
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateQuiz = /* GraphQL */ `
  mutation UpdateQuiz(
    $input: UpdateQuizInput!
    $condition: ModelQuizConditionInput
  ) {
    updateQuiz(input: $input, condition: $condition) {
      id
      organization
      label
      note
      placeholder
      quizType
      quizContentType
      correctOption
      options {
        nextToken
        startedAt
        __typename
      }
      answerOptions
      imgBanner
      episodeID
      order
      visibleIf
      triggers
      personalityAnalysis
      metaData
      isRequired
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteQuiz = /* GraphQL */ `
  mutation DeleteQuiz(
    $input: DeleteQuizInput!
    $condition: ModelQuizConditionInput
  ) {
    deleteQuiz(input: $input, condition: $condition) {
      id
      organization
      label
      note
      placeholder
      quizType
      quizContentType
      correctOption
      options {
        nextToken
        startedAt
        __typename
      }
      answerOptions
      imgBanner
      episodeID
      order
      visibleIf
      triggers
      personalityAnalysis
      metaData
      isRequired
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createQuizDependency = /* GraphQL */ `
  mutation CreateQuizDependency(
    $input: CreateQuizDependencyInput!
    $condition: ModelQuizDependencyConditionInput
  ) {
    createQuizDependency(input: $input, condition: $condition) {
      id
      dependencyType
      dependentQuizId
      isShowTextCondition
      conditions
      episodeID
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateQuizDependency = /* GraphQL */ `
  mutation UpdateQuizDependency(
    $input: UpdateQuizDependencyInput!
    $condition: ModelQuizDependencyConditionInput
  ) {
    updateQuizDependency(input: $input, condition: $condition) {
      id
      dependencyType
      dependentQuizId
      isShowTextCondition
      conditions
      episodeID
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteQuizDependency = /* GraphQL */ `
  mutation DeleteQuizDependency(
    $input: DeleteQuizDependencyInput!
    $condition: ModelQuizDependencyConditionInput
  ) {
    deleteQuizDependency(input: $input, condition: $condition) {
      id
      dependencyType
      dependentQuizId
      isShowTextCondition
      conditions
      episodeID
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createQuizOption = /* GraphQL */ `
  mutation CreateQuizOption(
    $input: CreateQuizOptionInput!
    $condition: ModelQuizOptionConditionInput
  ) {
    createQuizOption(input: $input, condition: $condition) {
      id
      label
      value
      order
      metaData
      quizID
      quizOptionTheme {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateQuizOption = /* GraphQL */ `
  mutation UpdateQuizOption(
    $input: UpdateQuizOptionInput!
    $condition: ModelQuizOptionConditionInput
  ) {
    updateQuizOption(input: $input, condition: $condition) {
      id
      label
      value
      order
      metaData
      quizID
      quizOptionTheme {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteQuizOption = /* GraphQL */ `
  mutation DeleteQuizOption(
    $input: DeleteQuizOptionInput!
    $condition: ModelQuizOptionConditionInput
  ) {
    deleteQuizOption(input: $input, condition: $condition) {
      id
      label
      value
      order
      metaData
      quizID
      quizOptionTheme {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createQuizOptionTheme = /* GraphQL */ `
  mutation CreateQuizOptionTheme(
    $input: CreateQuizOptionThemeInput!
    $condition: ModelQuizOptionThemeConditionInput
  ) {
    createQuizOptionTheme(input: $input, condition: $condition) {
      id
      quizOptionID
      theme
      url
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateQuizOptionTheme = /* GraphQL */ `
  mutation UpdateQuizOptionTheme(
    $input: UpdateQuizOptionThemeInput!
    $condition: ModelQuizOptionThemeConditionInput
  ) {
    updateQuizOptionTheme(input: $input, condition: $condition) {
      id
      quizOptionID
      theme
      url
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteQuizOptionTheme = /* GraphQL */ `
  mutation DeleteQuizOptionTheme(
    $input: DeleteQuizOptionThemeInput!
    $condition: ModelQuizOptionThemeConditionInput
  ) {
    deleteQuizOptionTheme(input: $input, condition: $condition) {
      id
      quizOptionID
      theme
      url
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createTheme = /* GraphQL */ `
  mutation CreateTheme(
    $input: CreateThemeInput!
    $condition: ModelThemeConditionInput
  ) {
    createTheme(input: $input, condition: $condition) {
      id
      name
      property
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateTheme = /* GraphQL */ `
  mutation UpdateTheme(
    $input: UpdateThemeInput!
    $condition: ModelThemeConditionInput
  ) {
    updateTheme(input: $input, condition: $condition) {
      id
      name
      property
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteTheme = /* GraphQL */ `
  mutation DeleteTheme(
    $input: DeleteThemeInput!
    $condition: ModelThemeConditionInput
  ) {
    deleteTheme(input: $input, condition: $condition) {
      id
      name
      property
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
