import React from 'react';
import './i18n';
import App from './App';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';


import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={configureStore({})}>
    <App />
  </Provider>
);