export const themeData = {
      "themeProerty" : {
        "colors": {
          "body": "#FFFFFF",
          "text": "#2A2D34",
          "subText":"#8A8E99",
          "button": {
            "text": "#FFFFFF",
            "background": "#2463FF"
          },
          "link": {
            "text": "#F83269"
          }
        },
        "font": "Manrope",
        "bgImage": "https://arena-incoming-clientlists-0038d267114153-prod.s3.amazonaws.com/Compass/compassBackground.png",
      }
}