export const customListQuizzesByEpisode = /* GraphQL */ `
  query GetEpisode($id: ID!) {
    getEpisode(id: $id) {
      id
      episodeType
      title
      subTitle
      episodeImage
      episodeLockImage
      levelBGImage
      levelBGLockImage
      displayMode
      landingTitle
      landinSubtitle
      landingImage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      quizs {
        items {
          id
          label
          note
          personalityAnalysis
          placeholder
          quizType
          quizContentType
          correctOption
          isQuizDependent
          dependentOnQuizIds
          dependentQuizziesAndAnswers
          _deleted
          options {
            items {
              id
              label
              value
              _deleted
              quizOptionTheme {
                items {
                  id
                  url
                  _deleted
                }
              }
            }
          }
          imgBanner
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
    }
  }
`;

// export const customGetEpisode = /* GraphQL */ `
//   query GetEpisode($id: ID!) {
//     getEpisode(id: $id) {
//       id
//       episodeType
//       title
//       subTitle
//       episodeImage
//       episodeLockImage
//       levelBGImage
//       levelBGLockImage
//       displayMode
//       landingTitle
//       landinSubtitle
//       landingImage
//       createdAt
//       updatedAt
//       _version
//       _deleted
//       _lastChangedAt
//       owner
//     }
//   }
// `;

export const getCustomJourney = /* GraphQL */ `
  query GetJourney($id: ID!) {
    getJourney(id: $id) {
      id
      organization
      title
      journeyType
      episodes {
        items {
          id
          journeyID
          episodeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          episode {
            id
            title
            episodeImage
            episodeLockImage
            episodeType
            displayMode
            landinSubtitle
            landingImage
            landingTitle
            levelBGImage
            levelBGLockImage
            order
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
        }
        nextToken
        startedAt
      }
      displayMode
      imgBanner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;

export const getCustomCampaign = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      organization
      name
      inviteText
      inviteURL
      rewardPointConversion
      contactModes
      currentContactModeIndex
      inviteEmail
      themeID
      enabled
      startDate
      endDate
      contactFrequency
      _version
    }
  }
`;

export const getCustomJourneyInEpisode = `  query GetJourney($id: ID!) {
  getJourney(id: $id) {
    id
    organization
    title
    journeyType
    episodes {
      items {
        id
        organization
        episodeType
        title
        subTitle
        episodeImage
        episodeLockImage
        levelBGImage
        levelBGLockImage
        displayMode
        landingTitle
        landinSubtitle
        landingImage
        journeyID
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
      __typename
    }
    displayMode
    imgBanner
    CampaignID
    order
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner
    __typename
  }
}`;

export const getCustomJourneyInfo = /* GraphQL */ `
  query GetJourney($id: ID!) {
    getJourney(id: $id) {
      id
      organization
      title
      journeyType
      displayMode
      imgBanner
      isBinge
      _version
    }
  }
`;

export const getcustomEpisodeInfo = /* GraphQL */ `
  query GetEpisode($id: ID!) {
    getEpisode(id: $id) {
      id
      episodeType
      title
      subTitle
      episodeImage
      episodeLockImage
      levelBGImage
      levelBGLockImage
      displayMode
      landingTitle
      landinSubtitle
      landingImage
      isReleased
      order
      _version
    }
  }
`;

export const customCampaignsInOrganization = /* GraphQL */ `
  query CampaignsInOrganization(
    $organization: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignsInOrganization(
      organization: $organization
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organization
        journeys {
          items {
            id
            title
            _deleted
          }
        }
        name
        inviteText
        inviteURL
        contactModes
        currentContactModeIndex
        inviteEmail
        theme {
          id
          name
          _version
          _deleted
        }
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;

export const CustomJourneysInCampaign = /* GraphQL */ `
  query JourneysInCampaign(
    $CampaignID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJourneyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    journeysInCampaign(
      CampaignID: $CampaignID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organization
        title
        journeyType
        episodes {
          items {
            id
            title
            _deleted
          }
        }
        displayMode
        imgBanner
        order
        CampaignID
        isBinge
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;

export const customEpisodesInJourney = /* GraphQL */ `
  query EpisodesInJourney(
    $journeyID: ID!
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEpisodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    episodesInJourney(
      journeyID: $journeyID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organization
        episodeType
        title
        subTitle
        episodeImage
        episodeLockImage
        quizs {
          items {
            label
            _deleted
          }
        }
        levelBGImage
        levelBGLockImage
        displayMode
        landingTitle
        landinSubtitle
        landingImage
        journeyID
        order
        _version
        _deleted
        isReleased
      }
      nextToken
      startedAt
    }
  }
`;

export const getcustomQuiz = /* GraphQL */ `
  query GetQuiz($id: ID!) {
    getQuiz(id: $id) {
      id
      organization
      label
      note
      personalityAnalysis
      placeholder
      quizType
      quizContentType
      correctOption
      imgBanner
      episodeID

      metaData
      _version
      isRequired
      options {
        items {
          id
          label
          value
          quizID
          order
          metaData
          _version
          _deleted
        }
        nextToken
        startedAt
      }
    }
  }
`;

export const getCustomOrganizationInfo = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      description
      streetAddress
      city
      state
      zipcode
      status
      contactName
      contactPhone
      contactEmail
      _version
      imgBanner
      clientLogoURL
      note
    }
  }
`;

export const CustomOrganizationList = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        S3IngestFileURL
        streetAddress
        city
        state
        zipcode
        clientLogoURL
        status
        orgState
        campaigns {
          items {
            id
            name
            _deleted
          }
          nextToken
          startedAt
        }
        contactName
        contactPhone
        contactEmail
        imgBanner
        slogan
        note
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;

export const customQuizzesInEpisode = /* GraphQL */ `
  query QuizzesInEpisode(
    $episodeID: ID!
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuizFilterInput
    $limit: Int
    $nextToken: String
  ) {
    quizzesInEpisode(
      episodeID: $episodeID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        note
        personalityAnalysis
        placeholder
        quizType
        quizContentType
        correctOption
        order

        _deleted
        options {
          items {
            id
            label
            value
            _deleted
            metaData
            order
            quizOptionTheme {
              items {
                id
                url
                _deleted
              }
            }
          }
        }
        imgBanner
        _version
        _deleted
        _lastChangedAt
        owner
        triggers
        visibleIf
        metaData
        isRequired
      }
      nextToken
      startedAt
    }
  }
`;

export const customQuizzesInEpisodeList = /* GraphQL */ `
  query customQuizzesInEpisodeList(
    $episodeID: ID!
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuizFilterInput
    $limit: Int
    $nextToken: String
  ) {
    quizzesInEpisode(
      episodeID: $episodeID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organization
        label
        note
        placeholder
        quizType
        quizContentType
        options {
          items {
            id
            label
            value
            _deleted
            metaData
            quizOptionTheme {
              items {
                id
                url
                _deleted
              }
            }
          }
          nextToken
          startedAt
        }
        metaData
        imgBanner
        episodeID
        order
        personalityAnalysis
        triggers
        visibleIf
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;

export const getCustomClinician = /* GraphQL */ `
  query GetCustomClinician($id: ID!) {
    getClinician(id: $id) {
      id
      firstName
      lastName
      mobilePhone
      email
      streetAddress
      streetAddress2
      city
      state
      zipcode
      thumbnail
      organization

      _version
    }
  }
`;

export const getCustomClient = /* GraphQL */ `
  query GetCustomClient($id: ID!) {
    getClient(id: $id) {
      id
      firstName
      lastName
      mobilePhone
      email
      campaign
      organization
      streetAddress
      streetAddress2
      city
      state
      zipcode
      thumbnail
      preferredContactMode
      ClinicianID
      _version
    }
  }
`;

export const customListNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        type
        clinicianID
        clientID
        client {
          id
          firstName
          lastName
          thumbnail
          organization
          campaign
          mobilePhone
          email
        }
        status
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCustomService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      name
      description
      value
      url
      organization
      _version
      thumbnail
    }
  }
`;

export const createCampaign = /* GraphQL */ `
  mutation CreateCampaign(
    $input: CreateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    createCampaign(input: $input, condition: $condition) {
      id
      organization
      journeys {
        nextToken
        startedAt
        __typename
      }
      name
      inviteText
      inviteURL
      rewardPointConversion
      contactModes
      currentContactModeIndex
      inviteEmail
      enabled
      startDate
      endDate
      contactFrequency
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
