import { API, graphqlOperation } from "aws-amplify";
// import { DataStore } from "@aws-amplify/datastore";
// import { Client, Organizations } from "../models";

// Import the query and mutation definitions

import {
  listClients,
  listOrganizations,
  clientByPhone,
  clientByEmail,
  getOrganization,
  getJourney,
  getEpisode,
  listQuizzes,
  listEpisodes,
  getClient,
  getCampaign,
  listCampaigns,
  listJourneys,
  listThemes,
  campaignsInOrganization,
  journeysInCampaign,
  episodesInJourney,
  quizzesInEpisode,
  quizOptionsInQuiz,
  quizDependenciesInEpisode,
  listReminders,
  listClinicians,
  getClinician,
  listNotifications,
  getNote,
  notesByClient,
  listServices,
  servicesInOrganization,
} from "./queries";
import {
  // customGetEpisode,
  customListQuizzesByEpisode,
  getCustomCampaign,
  getCustomJourney,
  getCustomJourneyInfo,
  getcustomEpisodeInfo,
  customCampaignsInOrganization,
  CustomJourneysInCampaign,
  customEpisodesInJourney,
  getcustomQuiz,
  getCustomOrganizationInfo,
  CustomOrganizationList,
  customQuizzesInEpisode,
  customQuizzesInEpisodeList,
  getCustomClinician,
  getCustomClient,
  customListNotifications,
  getCustomJourneyInEpisode,
  getCustomService,
} from "./customQueries";
import {
  createCampaign,
  createClient,
  createEpisode,
  createJourney,
  createOrganization,
  createQuiz,
  createQuizDependency,
  createQuizOption,
  deleteCampaign,
  deleteClient,
  deleteEpisode,
  deleteJourney,
  deleteOrganization,
  deleteQuiz,
  deleteQuizDependency,
  deleteQuizOption,
  updateCampaign,
  updateClient,
  updateEpisode,
  updateJourney,
  updateOrganization,
  updateQuiz,
  updateQuizDependency,
  updateQuizOption,
  createReminder,
  updateReminder,
  createClinician,
  updateClinician,
  deleteClinician,
  createNotification,
  deleteNotification,
  updateNotification,
  createNote,
  updateNote,
  deleteNote,
  createService,
  updateService,
  deleteService,
} from "./mutations";
import {
  customCreateCampaign,
  customCreateNotification,
  customDeleteCampaign,
  customDeleteNotification,
  customUpdateCampaign,
  customUpdateNotification,
} from "./customMutations";

// Read lists
export const getListQuery = async (queryType, filter) => {
  // console.log("getListing for: ", queryType);
  let response;
  if (queryType === "organization") {
    response = await API.graphql(graphqlOperation(listOrganizations));
  } else if (queryType === "getOrganization") {
    response = await API.graphql(graphqlOperation(getOrganization, filter));
  } else if (queryType === "getClientByPhone") {
    response = await API.graphql(graphqlOperation(clientByPhone, filter));
  } else if (queryType === "getClient") {
    response = await API.graphql(graphqlOperation(getClient, filter));
  } else if (queryType === "clientByEmail") {
    response = await API.graphql(graphqlOperation(clientByEmail, filter));
  } else if (queryType === "client") {
    response = await API.graphql(graphqlOperation(listClients, filter));
  }
  // else if(queryType === 'landingPageInOrganization'){
  // 	response = await API.graphql(graphqlOperation(landingPageInOrganization, filter))
  // }
  else if (queryType === "getJourney") {
    response = await API.graphql(graphqlOperation(getJourney, filter));
  } else if (queryType === "getEpisode") {
    response = await API.graphql(graphqlOperation(getEpisode, filter));
  } else if (queryType === "getClinician") {
    response = await API.graphql(graphqlOperation(getClinician, filter));
  } else if (queryType === "getCustomClinician") {
    response = await API.graphql(graphqlOperation(getCustomClinician, filter));
  }
  // else if(queryType === 'listTouchPoints'){
  // 	response = await API.graphql(graphqlOperation(listTouchPoints, filter));
  // }
  // else if(queryType === 'getTouchPoint'){
  // 	response = await API.graphql(graphqlOperation(getTouchPoint, filter));
  // }
  else if (queryType === "listQuizzes") {
    response = await API.graphql(graphqlOperation(listQuizzes, filter));
  }
  // else if(queryType === 'customListQuizzes'){
  // 	response = await API.graphql(graphqlOperation(customListQuizzes, filter));
  // }
  else if (queryType === "listEpisodes") {
    response = await API.graphql(graphqlOperation(listEpisodes, filter));
  } else if (queryType === "getCampaign") {
    response = await API.graphql(graphqlOperation(getCampaign, filter));
  } else if (queryType === "listJourneys") {
    response = await API.graphql(graphqlOperation(listJourneys, filter));
  } else if (queryType === "listCampaigns") {
    response = await API.graphql(graphqlOperation(listCampaigns, filter));
  } else if (queryType === "listThemes") {
    response = await API.graphql(graphqlOperation(listThemes, filter));
  } else if (queryType === "getCustomClient") {
    response = await API.graphql(graphqlOperation(getCustomClient, filter));
  } else if (queryType === "getNote") {
    response = await API.graphql(graphqlOperation(getNote, filter));
  } else if (queryType === "notesByClient") {
    response = await API.graphql(graphqlOperation(notesByClient, filter));
  }
  // else if (queryType === 'customGetEpisode') {
  //   response = await API.graphql(graphqlOperation(customGetEpisode, filter));
  // }
  else if (queryType === "customListQuizzesByEpisode") {
    response = await API.graphql(
      graphqlOperation(customListQuizzesByEpisode, filter)
    );
  } else if (queryType === "getCustomJourney") {
    response = await API.graphql(graphqlOperation(getCustomJourney, filter));
  } else if (queryType === "getCustomJourneyInEpisode") {
    response = await API.graphql(
      graphqlOperation(getCustomJourneyInEpisode, filter)
    );
  } else if (queryType === "getCustomCampaign") {
    response = await API.graphql(graphqlOperation(getCustomCampaign, filter));
  } else if (queryType === "campaignsInOrganization") {
    response = await API.graphql(
      graphqlOperation(campaignsInOrganization, filter)
    );
  } else if (queryType === "journeysInCampaign") {
    response = await API.graphql(graphqlOperation(journeysInCampaign, filter));
  } else if (queryType === "getCustomJourneyInfo") {
    response = await API.graphql(
      graphqlOperation(getCustomJourneyInfo, filter)
    );
  } else if (queryType === "episodesInJourney") {
    response = await API.graphql(graphqlOperation(episodesInJourney, filter));
  } else if (queryType === "getcustomEpisodeInfo") {
    response = await API.graphql(
      graphqlOperation(getcustomEpisodeInfo, filter)
    );
  } else if (queryType === "quizzesInEpisode") {
    response = await API.graphql(graphqlOperation(quizzesInEpisode, filter));
  } else if (queryType === "customQuizzesInEpisode") {
    response = await API.graphql(
      graphqlOperation(customQuizzesInEpisode, filter)
    );
  } else if (queryType === "customCampaignsInOrganization") {
    response = await API.graphql(
      graphqlOperation(customCampaignsInOrganization, filter)
    );
  } else if (queryType === "CustomJourneysInCampaign") {
    response = await API.graphql(
      graphqlOperation(CustomJourneysInCampaign, filter)
    );
  } else if (queryType === "customEpisodesInJourney") {
    response = await API.graphql(
      graphqlOperation(customEpisodesInJourney, filter)
    );
  } else if (queryType === "getcustomQuiz") {
    response = await API.graphql(graphqlOperation(getcustomQuiz, filter));
  } else if (queryType === "quizOptionsInQuiz") {
    response = await API.graphql(graphqlOperation(quizOptionsInQuiz, filter));
  } else if (queryType === "getCustomOrganizationInfo") {
    response = await API.graphql(
      graphqlOperation(getCustomOrganizationInfo, filter)
    );
  } else if (queryType === "CustomOrganizationList") {
    response = await API.graphql(
      graphqlOperation(CustomOrganizationList, filter)
    );
  } else if (queryType === "customQuizzesInEpisodeList") {
    response = await API.graphql(
      graphqlOperation(customQuizzesInEpisodeList, filter)
    );
  } else if (queryType === "getCustomService") {
    response = await API.graphql(graphqlOperation(getCustomService, filter));
  } else if (queryType === "quizDependenciesInEpisode") {
    response = await API.graphql(
      graphqlOperation(quizDependenciesInEpisode, filter)
    );
  } else if (queryType === "listReminders") {
    response = await API.graphql(graphqlOperation(listReminders, filter));
  } else if (queryType === "listClinicians") {
    response = await API.graphql(graphqlOperation(listClinicians, filter));
  } else if (queryType === "listNotifications") {
    response = await API.graphql(graphqlOperation(listNotifications, filter));
  } else if (queryType === "listServices") {
    response = await API.graphql(graphqlOperation(listServices, filter));
  } else if (queryType === "customListNotifications") {
    response = await API.graphql(graphqlOperation(customListNotifications, filter));
  } else if (queryType === "servicesInOrganization") {
    response = await API.graphql(
      graphqlOperation(servicesInOrganization, filter)
    );
  } else {
    alert(JSON.stringify("Unknown GraphQL query type"));
  }
  //	alert(JSON.stringify(response, null, 2));
  return response;
};

// Create (Mutate) a single new element by type
export const createSingleEntityMutation = async (entityType, input) => {
  // console.log("creating single entity of: " +  entityType );
  // console.log("with input: " +  JSON.stringify(input));
  let response;
  if (entityType === "organization") {
    response = await API.graphql(
      graphqlOperation(createOrganization, { input })
    );
  } else if (entityType === "client") {
    response = await API.graphql(graphqlOperation(createClient, { input }));
  } else if (entityType === "journey") {
    console.log("utilities.js - create journey with payload: ", input);
    response = await API.graphql(graphqlOperation(createJourney, { input }));
  } else if (entityType === "episode") {
    //		console.log("utilities.js - create epidode with payload: ", input);
    response = await API.graphql(graphqlOperation(createEpisode, { input }));
  } else if (entityType === "game") {
    //		response = await API.graphql(graphqlOperation(createGame(input)));
  } else if (entityType === "customCreateCampaign") {
    response = await API.graphql(
      graphqlOperation(customCreateCampaign, { input })
    );
  } else if (entityType === "createQuiz") {
    response = await API.graphql(graphqlOperation(createQuiz, { input }));
  } else if (entityType === "createQuizOption") {
    response = await API.graphql(graphqlOperation(createQuizOption, { input }));
  } else if (entityType === "createQuizDependency") {
    response = await API.graphql(
      graphqlOperation(createQuizDependency, { input })
    );
  } else if (entityType === "createReminder") {
    response = await API.graphql(graphqlOperation(createReminder, { input }));
  } else if (entityType === "createService") {
    response = await API.graphql(graphqlOperation(createService, { input }));
  } else if (entityType === "createClinician") {
    response = await API.graphql(graphqlOperation(createClinician, { input }));
  } else if (entityType === "createNotification") {
    response = await API.graphql(
      graphqlOperation(createNotification, { input })
    );
  } else if (entityType === "customCreateNotification") {
    response = await API.graphql(
      graphqlOperation(customCreateNotification, { input })
    );
  } else if (entityType === "createNote") {
    response = await API.graphql(graphqlOperation(createNote, { input }));
  } else {
    alert(JSON.stringify("Unknown GraphQL mutation type"));
  }
  return response;
};

// Create (Mutate) a single new element by type
export const deleteSingleEntityMutation = async (entityType, input) => {
  // console.log('deleting single entity of: ' + entityType);
  //	console.log("with input: " +  JSON.stringify(input));
  let response;
  if (entityType === "organization") {
    response = await API.graphql(
      graphqlOperation(deleteOrganization, { input })
    );
  } else if (entityType === "client") {
    response = await API.graphql(graphqlOperation(deleteClient, { input }));
  } else if (entityType === "journey") {
    response = await API.graphql(graphqlOperation(deleteJourney, { input }));
  } else if (entityType === "episode") {
    response = await API.graphql(graphqlOperation(deleteEpisode, { input }));
  } else if (entityType === "customDeleteCampaign") {
    response = await API.graphql(
      graphqlOperation(customDeleteCampaign, { input })
    );
  } else if (entityType === "deleteQuiz") {
    response = await API.graphql(graphqlOperation(deleteQuiz, { input }));
  } else if (entityType === "deleteQuizOption") {
    response = await API.graphql(graphqlOperation(deleteQuizOption, { input }));
  } else if (entityType === "deleteClinician") {
    response = await API.graphql(graphqlOperation(deleteClinician, { input }));
  } else if (entityType === "deleteNote") {
    response = await API.graphql(graphqlOperation(deleteNote, { input }));
  } else if (entityType === "deleteQuizDependency") {
    response = await API.graphql(
      graphqlOperation(deleteQuizDependency, { input })
    );
  } else if (entityType === "deleteService") {
    response = await API.graphql(graphqlOperation(deleteService, { input }));
  } else if (entityType === "deleteNotification") {
    // eslint-disable-next-line no-unused-vars
    response = await API.graphql(
      graphqlOperation(deleteNotification, { input })
    );
  } else if (entityType === "customDeleteNotification") {
    // eslint-disable-next-line no-unused-vars
    response = await API.graphql(
      graphqlOperation(customDeleteNotification, { input })
    );
  } else {
    alert(JSON.stringify("Unknown GraphQL mutation type"));
  }
};

export const updateSingleEntityMutation = async (entityType, input) => {
  let response;
  switch (entityType) {
    case "updateClient":
      response = await API.graphql(graphqlOperation(updateClient, { input }));
      break;
    case "updateOrganization":
      response = await API.graphql(
        graphqlOperation(updateOrganization, { input })
      );
      break;
    case "updateJourney":
      response = await API.graphql(graphqlOperation(updateJourney, { input }));
      break;
    case "updateEpisode":
      response = await API.graphql(graphqlOperation(updateEpisode, { input }));
      break;
    case "updateQuiz":
      response = await API.graphql(graphqlOperation(updateQuiz, { input }));
      break;
    case "updateQuizOption":
      response = await API.graphql(
        graphqlOperation(updateQuizOption, { input })
      );
      break;
    case "updateQuizDependency":
      response = await API.graphql(
        graphqlOperation(updateQuizDependency, { input })
      );
      break;
    case "customUpdateCampaign":
      response = await API.graphql(
        graphqlOperation(customUpdateCampaign, { input })
      );
      break;
    case "updateReminder":
      response = await API.graphql(graphqlOperation(updateReminder, { input }));
      break;
    case "updateNote":
      response = await API.graphql(graphqlOperation(updateNote, { input }));
      break;
    case "updateClinician":
      response = await API.graphql(
        graphqlOperation(updateClinician, { input })
      );
      break;
    case "updateService":
      response = await API.graphql(graphqlOperation(updateService, { input }));
      break;
    case "updateNotification":
      response = await API.graphql(
        graphqlOperation(updateNotification, { input })
      );
      break;
      case "customUpdateNotification":
        response = await API.graphql(
          graphqlOperation(customUpdateNotification, { input })
        );
      break;
    default:
      break;
  }
  return response;
};
