// import { Navigate, useLocation } from 'react-router-dom';
// import { APICore } from '../helpers/api/apiCore';

// type PrivateRouteProps = {
//     component: React.ComponentType,
//     roles?: string,
// };

// /**
//  * Private Route forces the authorization before the route can be accessed
//  * @param {*} param0
//  * @returns
//  */

// const PrivateRoute = ({ component: RouteComponent, roles, ...rest }: PrivateRouteProps) => {
//     let location = useLocation();
//     const api = new APICore();
//     const loggedInUser = api.getLoggedInUser();

//     /**
//      * not logged in so redirect to login page with the return url
//      */
//     console.log(api.isUserAuthenticated());
//     if (api.isUserAuthenticated() === false) {
//         console.log("PrivateRoute api.isUserAuthenticated() returned:", false);

//         return <Navigate to={'/account/login'} state={{ from: location }} replace />;
//     }
//     console.log("PrivateRoute api.isUserAuthenticated() returned:", true);
//     // check if route is restricted by role
// /* We're not supporting roles yet... comment out
//     if (roles && roles.indexOf(loggedInUser.role) === -1) {
//             return <Navigate to={{ pathname: '/' }} />;     // role not authorised so redirect to home page
//     }
// */
//     return <RouteComponent />;
// };

import { useAuthenticator } from "@aws-amplify/ui-react";
import { Navigate, useLocation } from "react-router-dom";
import { APICore } from "../helpers/api/apiCore";
import { getUserRole } from "../utils/utilities";

function PrivateRoute({ component: RouteComponent, roles, ...rest }) {
  const api = new APICore();
  const location = useLocation();
  api.isUserAuthenticated();
  const { route } = useAuthenticator((context) => [context.route]);
  return route === "authenticated" ? (
    getUserRole() &&
    (roles ? getUserRole()?.find((role) => roles.includes(role)) : false) ? (
      <RouteComponent />
    ) : (
      <Navigate to="/dashboard/403" state={{ from: location }} />
    )
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
}

export default PrivateRoute;
