import axios from "axios";
import { toast } from "react-toastify";
import { getListQuery, updateSingleEntityMutation } from "../graphql/utilities";
import { API_ENDPOINT } from "../constants/arena";
import { Auth } from "aws-amplify";

/**
 * @function setToLS - Set the data in the localstorage
 * @param key - key for the localstorage
 * @param value - Value of the localstorage
 */
export const setToLS = (key, value) => {
  if (key && value) localStorage.setItem(key, JSON.stringify(value));
};

/**
 * @function getFromLS - get the data from the localstorage
 * @param key - key for the localstorage
 */
export const getFromLS = (key) => {
  // console.log("browser localStorage getting: ", key);

  const value = localStorage.getItem(key);
  if (value) {
    // console.log("browser localStorage getting value: ", JSON.parse(value));
    return JSON.parse(value);
  }
};

/**
 * @function showToast - to display toaster messages
 * @param text - text content for toaster
 * @param type - type of the toaster [info, success, warning, error]
 * @param config - configurations for the toaster
 */
export const showToast = (text, type, config) => {
  const t = type ? toast[type] : toast;
  t(text, {
    position: config?.position || "top-right",
    autoClose: config?.autoClose || 5000,
    hideProgressBar: config?.hideProgressBar || true,
    closeOnClick: config?.closeOnClick || true,
    pauseOnHover: config?.pauseOnHover || true,
    draggable: config?.draggable || true,
    progress: config?.progress || undefined,
  });
};

/**
 * @function getState - Set the State of the Episodes and Touch Points State are ['completed','lock','open']
 * @param instance - JSON Object
 * @param currentInstance - JSON Object
 */
export const getState = (instance, currentInstance) => {
  if (instance && currentInstance) {
    let state;
    if (
      instance.id !== currentInstance.id &&
      currentInstance.order > instance.order
    ) {
      state = "completed";
    } else if (
      instance.id !== currentInstance.id &&
      currentInstance.order < instance.order
    ) {
      state = "lock";
    } else {
      state = "open";
    }
    return state;
  }
};

/**
 * @funciton getClientFromDB - Get client from the database
 * @param data - object
 * @returns Client object
 */
export const getClientFromDB = async (data) => {
  if (data) {
    const response = await getListQuery("getClient", { id: data?.id });
    return response;
  }
};

/**
 * @funciton updateClient - Update client data
 * @param input - Input Parameter
 * @returns Client object
 */
export const updateClient = async (input) => {
  const response = await updateSingleEntityMutation("updateClient", input);
  return response;
};

/**
 * @funciton sizePerPageList - Set the pagesize in the table pagination
 * @returns Page size array
 */

// Create a function to get the presigned URL from the server
export async function getPresignedUrl(payload) {
  const response = await axios.get(
    `${API_ENDPOINT}/api/v1/get-presigned-url`,
    payload
  );
  return response.data;
}

// Create a function to get the s3 file list from the server
export async function getS3FileList(payload) {
  try {
    const response = await axios.get(
      `${API_ENDPOINT}/api/v1/get-s3-file-list`,
      {
        params: payload, // Pass the payload as the request query parameters
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching images:", error);
    throw error; // Rethrow the error so that the caller can handle it
  }
}

// Create a function to delete the s3 file list from the server
export async function deleteS3FileList(payload) {
  const response = await axios.post(
    `${API_ENDPOINT}/api/v1/delete-s3-file-list`,
    payload
  );
  return response;
}

// Create a function to upload the file using the presigned URL
export async function uploadFile(presignedUrl, file) {
  const response = await axios.put(presignedUrl, file);
  return response.status;
}

export const handleFileUpload = async (payload) => {
  const { selectedFile, postParam } = payload;
  if (selectedFile !== null) {
    if(postParam.params.fileName){
      postParam.params.fileName = postParam.params.fileName.replace(/ /g,"_");
    }
    const presignedUrlData = await getPresignedUrl(postParam);
    const uplodedUrlStatus = await uploadFile(
      presignedUrlData.signedUrl,
      selectedFile
    );
    if (uplodedUrlStatus === 200) {
      return {
        uploadedFileUrl: presignedUrlData.uploadedFileUrl,
      };
    }
  }
};

export const splitURL = (url) => {
  try {
    if (url) {
      const s3BaseUrlPattern = /https:\/\/[^/]+\.s3\.amazonaws\.com\/(.*)/;
      const matches = url.match(s3BaseUrlPattern);
      if (matches && matches.length >= 2) {
        return matches[1];
      } else {
        console.error("Unable to extract path from S3 URL");
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, ""); // Remove non-numeric characters
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3];
  }

  return phoneNumber; // Return the original number if the format doesn't match
};

//get user role
export const getUserRole = () => {
  return Auth?.user?.signInUserSession?.accessToken?.payload["cognito:groups"];
};

export const isOrgAdmin = () => {
  return getUserRole().includes("OrganizationAdministrators");
};

export const isClinician = () => {
  return getUserRole().includes("Clinician");
};

export const isClient = () => {
  return getUserRole().includes("Clients");
};

export const isAdmin = () => {
  return getUserRole().includes("Administrators");
};

//get user email
export const getUserEmail = () => {
  return Auth?.user?.signInUserSession?.idToken.payload.email;
};

export const formattedDate = (date) => {
  const dateObject = new Date(date);
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObject.getDate().toString().padStart(2, "0");
  const year = dateObject.getFullYear();
  return `${month}/${day}/${year}`;
};

//set  organization info to localstorage
export const getOrganizationInfo = async () => {
  try {
    const payload = {
      filter: {
        contactEmail: {
          contains: getUserEmail(),
        },
      },
    };
    const response = await getListQuery("CustomOrganizationList", payload);
    const data = response.data.listOrganizations.items.filter(
      (item) => !item._deleted
    );

    return data[0];
  } catch (error) {
    console.log(error);
  }
};

//downdoad Image
export const downloadImage = (image) => {
  // Create a temporary anchor element to initiate the download
  const downloadLink = document.createElement("a");
  downloadLink.href = image.url;
  downloadLink.download = image.name;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const fetchImageBlobFromURL = async (imageURL) => {
  try {
    const response = await fetch(imageURL);
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error("Error fetching image blob: ", error);
    return null;
  }
};

export const createFileFromBlob = (blob, filename, type) => {
  return new File([blob], filename, { type });
};

export const Base64ToFile = (base64Image) => {
  const binaryData = atob(base64Image);
  const byteArray = new Uint8Array(binaryData.length);
  const match = binaryData.match(/filename=(.*?)&/);
  const timestamp = new Date().getTime();
  const fileName = match ? match[1] : `image_${timestamp}.png`;
  for (let i = 0; i < binaryData.length; i++) {
    byteArray[i] = binaryData.charCodeAt(i);
  }
  const blob = new Blob([byteArray], { type: "image/png" });
  const file = new File([blob], fileName, { type: "image/png" });
  return file;
};

export const getEnvUrl = () => {
  const envUrl =
    process.env.REACT_APP_USER_BRANCH === "prod"
      ? "https://www.heroicarena.com"
      : "https://develop.d21sd6dsivrck0.amplifyapp.com";
  return envUrl;
};

/**
 * @function categorizeByDate
 * @param inputArray - inputArray for the array of the input.
 * @returns the json array categorize by date
 */
export const categorizeByDate = (inputArray) => {
  const initialValue = { today: [], yesterday: [] };
  const categorizedData = inputArray.reduce((result, item) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const itemDate = new Date(item.timestamp).toLocaleDateString(
      "en-US",
      options
    );

    if (itemDate === new Date().toLocaleDateString("en-US", options)) {
      result.today.push(item);
    } else if (
      itemDate ===
      new Date(new Date().getTime() - 86400000).toLocaleDateString(
        "en-US",
        options
      )
    ) {
      result.yesterday.push(item);
    } else {
      if (!result[itemDate]) {
        result[itemDate] = [];
      }
      result[itemDate].push(item);
    }
    return result;
  }, initialValue);
  return categorizedData;
};

/**
 * @function formatDayString
 * @param dateString - dateString for the date
 * @returns Return the date in the string format ex. Dec 03, 2023
 */
export const formatDayString = (dateString) => {
  if (!dateString) {
    return;
  }
  const date = new Date(dateString);
  if (isNaN(date) || date.toString() === "Invalid Date") {
    return dateString;
  }
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
  return `${formattedDate}`;
};

/**
 * @function getTimeAgo
 * @param dateString - dateString for the date
 * @returns Return the time and days ex. 10 mins ago, 2 days ago
 */
export const getTimeAgo = (dateString) => {
  if (!dateString) {
    return;
  }
  const currentDate = new Date();
  const date = new Date(dateString);
  if (isNaN(date) || date.toString() === "Invalid Date") {
    return dateString;
  }
  const timeDifference = currentDate.getTime() - date.getTime();
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  if (days > 0) {
    return `${days} day${days === 1 ? "" : "s"} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours === 1 ? "" : "s"} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
  } else {
    return "Just now";
  }
};
