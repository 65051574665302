import { themeData } from "../../assets/js/themeProperty";
import { getFromLS } from "../../utils/utilities";
import { ThemeActionTypes } from "./constants";

const INIT_STATE = {
    themeProperty: getFromLS('themeData') || themeData.themeProerty
}

const theme = (state = INIT_STATE, action) => {
    switch(action.type){
        case ThemeActionTypes.ADD_THEME_PROPERTY:
        return{
            ...state,
            themeProperty: action.data
        }; 
        default:
            return state;
    }
}

export default theme;