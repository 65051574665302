export const customCreateCampaign = /* GraphQL */ `
  mutation CreateCampaign(
    $input: CreateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    createCampaign(input: $input, condition: $condition) {
      id
      organization
      journeys {
        nextToken
        startedAt
        __typename
      }
      name
      inviteText
      inviteURL
      rewardPointConversion
      contactModes
      currentContactModeIndex
      inviteEmail
      enabled
      startDate
      endDate
      contactFrequency
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const customUpdateCampaign = /* GraphQL */ `
  mutation UpdateCampaign(
    $input: UpdateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    updateCampaign(input: $input, condition: $condition) {
      id
      organization
      journeys {
        nextToken
        startedAt
        __typename
      }
      name
      inviteText
      inviteURL
      rewardPointConversion
      contactModes
      currentContactModeIndex
      inviteEmail
      enabled
      startDate
      endDate
      contactFrequency
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const customDeleteCampaign = /* GraphQL */ `
  mutation DeleteCampaign(
    $input: DeleteCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    deleteCampaign(input: $input, condition: $condition) {
      id
      organization
      journeys {
        nextToken
        startedAt
        __typename
      }
      name
      inviteText
      inviteURL
      rewardPointConversion
      contactModes
      currentContactModeIndex
      inviteEmail
      enabled
      startDate
      endDate
      contactFrequency
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;

export const customCreateNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      text
      clinicianID
      clientID
      status
      timestamp
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;

export const customUpdateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      text
      clinicianID
      clientID
      status
      timestamp
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;

export const customDeleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      text
      clinicianID
      clientID
      status
      timestamp
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;